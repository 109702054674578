import {
  CellId,
  ClientAnalyticsEventType,
  DataBrowserDialogOpenedSource,
  DataBrowserTab,
  DataConnectionId,
  DataSourceTableId,
  KernelState,
  START_APP_SESSION,
} from "@hex/common";
import { useCallback } from "react";

import { useKernelSelector } from "../../../appsession-multiplayer/state-hooks/kernelStateHooks.js";
import {
  LocalStorageKeys,
  useLocalStorageSetter,
} from "../../../hooks/useLocalStorage.js";
import { useDispatch } from "../../../redux/hooks.js";
import { setCellInsertInfo } from "../../../redux/slices/dataBrowserSlice.js";
import {
  collapseAllNodes,
  setNodeSelected,
} from "../../../redux/slices/schemaTreeBrowserSlice.js";
import { useAppSessionAOContext } from "../../../util/appSessionAOContext.js";
import { useDialog } from "../../../util/dialogs.js";
import { trackEvent } from "../../../util/trackEvent.js";
import { CellInsertType } from "../../cell/types.js";

/**
 * This wraps the generic useDialog hook - this will also attempt to start the
 * kernel if kernel is stopped when opening the data browser.
 */

export const useDataBrowserDialog = (args: {
  /**
   * The cell id that the data browser was opened from.
   */
  selectedCellId?: CellId;
  /**
   * This is used to determine where to insert a new cell.
   */
  targetCellId?: CellId;
  /**
   * Defaults to GLOBAL if not specified
   */
  insertType?: CellInsertType;
  /**
   * For tracking purposes
   */
  source: DataBrowserDialogOpenedSource;
}): {
  /**
   * Use this if you want to open a specific table in the data browser dialog
   * from outside the data browser. This currently only supports opening the
   * table in the "data" tab in the "browser" tree view. Otherwise, use
   * @openDataBrowser to open the data browser.
   */
  openTableInDataBrowser: (tableInfo: {
    tableId: DataSourceTableId;
    dataConnectionId: DataConnectionId;
    idPath?: string[];
  }) => void;
  openDataBrowser: () => void;
  closeDataBrowser: (args?: { navigate: boolean }) => void;
  isOpen: boolean;
} => {
  const { insertType, selectedCellId, source, targetCellId } = args;

  const {
    closeDialog,
    isOpen,
    openDialog: openDataBrowser_,
  } = useDialog(
    selectedCellId ? `data-browser-${selectedCellId}` : `data-browser`,
  );

  const kernelStopped = useKernelSelector({
    selector: (k) => k?.state == null || k.state === KernelState.STOPPED,
    safe: true,
  });

  const { dispatchAO: dispatchAppSessionAO } = useAppSessionAOContext();
  const setSidebarConnectionId = useLocalStorageSetter(
    LocalStorageKeys.SCHEMA_BROWSER_CONNECTION(),
  );
  const setCurrentTab = useLocalStorageSetter(
    LocalStorageKeys.DATA_BROWSER_VIEW(),
  );

  const dispatch = useDispatch();

  const openTableInDataBrowser = useCallback(
    (tableInfo?: {
      tableId: DataSourceTableId;
      dataConnectionId: DataConnectionId;
      // if specified, this will expand the schema browser nodes included in
      // this id path and collapse all others
      idPath?: string[];
    }) => {
      if (tableInfo) {
        const { dataConnectionId, idPath, tableId } = tableInfo;

        setCurrentTab(DataBrowserTab.DATA);
        setSidebarConnectionId(dataConnectionId);

        dispatch(
          collapseAllNodes({
            treeType: "browser",
            dataConnectionId,
          }),
        );
        dispatch(
          setNodeSelected({
            dataConnectionId,
            nodeId: tableId,
            isSelected: true,
            treeType: "browser",
            nodeType: "table",
            idPath,
          }),
        );
      }
      trackEvent(ClientAnalyticsEventType.DATA_BROWSER_DIALOG_OPENED, {
        dataBrowserOpenedSource: source,
      });
      openDataBrowser_();

      dispatch(
        setCellInsertInfo({
          targetCellId,
          insertType: insertType ?? CellInsertType.GLOBAL,
        }),
      );

      if (kernelStopped) {
        dispatchAppSessionAO(START_APP_SESSION.create());
      }
    },
    [
      source,
      openDataBrowser_,
      targetCellId,
      kernelStopped,
      setCurrentTab,
      setSidebarConnectionId,
      dispatch,
      insertType,
      dispatchAppSessionAO,
    ],
  );

  const openDataBrowser = useCallback(() => {
    openTableInDataBrowser();
  }, [openTableInDataBrowser]);

  return {
    openTableInDataBrowser,
    openDataBrowser,
    closeDataBrowser: closeDialog,
    isOpen,
  };
};
